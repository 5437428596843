<template>
    <v-container fluid>

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <v-select v-model="entityModel" :items="entityLists" item-value="company_id" chips multiple item-text="company_name" label="Entity" @change="(event) => entityOnChange(event)" dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 400px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer2" style="height: 400px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer3" style="height: 400px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer4" style="height: 400px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer5" style="height: 400px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer6" style="height: 400px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="chartContainer7" style="height: 600px; width: 100%;"></div>
                    </div>
                </div>
            </div>

        </div>

    </v-container>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'VIVA Kencana',
                disabled: false,
                href: '/admin/viva',
                },
                {
                text: 'HRD',
                disabled: false,
                href: '#',
                },
                {
                text: 'Overview',
                disabled: true,
                href: '#',
                },
            ],
            entityModel: '',
            entityLists: [],
            titleChart: '',
            userEntity: []
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){

            this.$store.dispatch('setOverlay', true)
            
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/overview`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.entityLists = res.data.entity

                if(res.data.hrisEnv != 'not set'){

                    let array = res.data.hrisEnv.split(",");

                    this.userEntity = array

                } else {

                    Swal.fire({
                        text: 'This user has not yet been set to a company, call IT administrator',
                        icon: 'warning',
                    })

                    // this.$router.push('/admin/viva');
                }

                

                this.chartTotalEmployee(this.userEntity)
                this.chartEmployeeByType(this.userEntity)
                this.chartEmployeeByStat(this.userEntity)
                this.chartEmployeeGender(this.userEntity)
                this.chartEmployeeAge(this.userEntity)
                this.chartEmployeeWorkAge(this.userEntity)
                this.chartEmployeeEducation(this.userEntity)
                
                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                console.log(err);

            })

        },

        async chartTotalEmployee(company_id){

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/overview/chartTotalEmployee`, { 
                company_id: company_id ? company_id : this.userEntity
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                    
                this.renderChart1('chartContainer1', company_id ? 'Total Employee(' + company_id + ')' : 'Total Employee', res.data.result)

            })
            .catch(err => {

                console.log(err)

            })

        },

        async chartEmployeeByType(company_id){

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/overview/chartEmployeeByType`, { 
                company_id: company_id ? company_id : this.userEntity
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                    
                this.renderChart1('chartContainer2', company_id ? 'Total Employee By Type(' + company_id + ')' : 'Total Employee By Type', res.data.result)

            })
            .catch(err => {

                console.log(err)

            })

        },

        async chartEmployeeByStat(company_id){

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/overview/chartEmployeeByStat`, { 
                company_id: company_id ? company_id : this.userEntity
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                    
                this.renderChart1('chartContainer3', company_id ? 'Total Employee By Stat(' + company_id + ')' : 'Total Employee By Stat', res.data.result)

            })
            .catch(err => {

                console.log(err)

            })

        },

        async chartEmployeeAbsentByType(company_id){

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/overview/chartEmployeeAbsentByType`, { 
                company_id: company_id ? company_id : this.userEntity
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                    
                this.renderChart1('chartContainer4', company_id ? 'Total Employee Absent By Stat(' + company_id + ')' : 'Total Employee Absent By Stat', res.data.result)

            })
            .catch(err => {

                console.log(err)

            })

        },

        async chartEmployeeGender(company_id){

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/overview/chartEmployeeGender`, { 
                company_id: company_id ? company_id : this.userEntity
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                    
                this.renderChart1('chartContainer4', company_id ? 'Total Employee By Gender(' + company_id + ')' : 'Total Employee By Gender', res.data.result)

            })
            .catch(err => {

                console.log(err)

            })

        },

        async chartEmployeeAge(company_id){

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/overview/chartEmployeeAge`, { 
                company_id: company_id ? company_id : this.userEntity
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                    
                this.renderChart2('chartContainer5', company_id ? 'Total Employee By Age(' + company_id + ')' : 'Total Employee By Age', res.data.result)

            })
            .catch(err => {

                console.log(err)

            })

        },

        async chartEmployeeWorkAge(company_id){

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/overview/chartEmployeeWorkAge`, { 
                company_id: company_id ? company_id : this.userEntity
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                    
                this.renderChart2('chartContainer6', company_id ? 'Total Employee By Age of Work(' + company_id + ')' : 'Total Employee By Age of Work', res.data.result)

            })
            .catch(err => {

                console.log(err)

            })

        },

        async chartEmployeeEducation(company_id){

            await axios.post(`${process.env.VUE_APP_URL}/api/viva/overview/chartEmployeeEducation`, { 
                company_id: company_id ? company_id : this.userEntity
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                    
                this.renderChart1('chartContainer7', company_id ? 'Total Employee By Education(' + company_id + ')' : 'Total Employee By Education', res.data.result)

            })
            .catch(err => {

                console.log(err)

            })

        },

        async renderChart1(container, title, data){

            var chart1 = new CanvasJS.Chart(container, {
                animationEnabled: true,
                exportEnabled: true,
                backgroundColor: "#bde0fe",
                title: {
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                theme: "light2",
                legend: {
                    fontSize: 10,
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{
                    type: "pie",
                    indexLabel: "{label} : {y}",
                    indexLabelFontSize: 12,
                    showInLegend: true,
                    indexLabelFontColor: "black",
                    legendText: "{label}",
                    dataPoints: data,

                }]
            })

            chart1.render()

        },

        async renderChart2(container, title, data){

            var chart1 = new CanvasJS.Chart(container, {
                animationEnabled: true,
                exportEnabled: true,
                backgroundColor: "#bde0fe",
                title: {
                    text: title,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                theme: "light2",
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "column",
                    indexLabel: "{label} : {y}",
                    indexLabelFontSize: 10,
                    // showInLegend: true,
                    indexLabelFontColor: "black",
                    // legendText: "{label}",
                    dataPoints: data,

                }]
            })

            chart1.render()

        },

        entityOnChange(id){

            console.log(id);

            if(id.length != 0){
                this.chartTotalEmployee(id)
                this.chartEmployeeByType(id)
                this.chartEmployeeByStat(id)
                this.chartEmployeeGender(id)
                this.chartEmployeeAge(id)
                this.chartEmployeeWorkAge(id)
                this.chartEmployeeEducation(id)
            } else {
                this.chartTotalEmployee(this.userEntity)
                this.chartEmployeeByType(this.userEntity)
                this.chartEmployeeByStat(this.userEntity)
                this.chartEmployeeGender(this.userEntity)
                this.chartEmployeeAge(this.userEntity)
                this.chartEmployeeWorkAge(this.userEntity)
                this.chartEmployeeEducation(this.userEntity)
            }

        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>